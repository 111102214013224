<template>
	<!-- <el-drawer :wrapperClosable="false" :title="$t('i18nn_ebd257df8f7efc53')" append-to-body :visible.sync="dialogFile"
		:direction="'rtl'" size="500px"> -->
	<el-dialog :title="'上传并覆盖面单'" :close-on-click-modal="false" append-to-body :visible.sync="dialogFile" width="600px">
		<div>
			<el-card>
				<!-- <div slot="header" class="">
					<h3>
						<i class="el-icon-upload2"></i>
						<span>替换面单</span>
					</h3>
				</div> -->
				
				<el-alert title="提示:提交后会覆盖原面单地址." type="warning" show-icon :closable="false"></el-alert>
				
				<HyElWhUpLoad ref="HyElWhUpLoad" :listType="'text'" :watchKey="fileWatchKey" :fileListShow="fileList"
					:fileId="fileId" :folder="'label_url/'" :accept="'application/pdf'" :btnText="$t('i18nn_e11679a643689255')"
					:maxSizeMB="5" :limit="1" v-on:UploadSuccessUrl="FileUploadSuccessUrl"></HyElWhUpLoad>

			</el-card>
		</div>

		<div class="dialog-footer">
			<el-button type="warning" @click="submitExcelFormAction()" icon="el-icon-document">确定</el-button>
		</div>

		<!-- </el-drawer> -->
	</el-dialog>
</template>
<script>
	// import whLabelFileNameMatch from '@/components/StorageCenter/shipments/whLabelFileNameMatch.vue';
	import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	// import whLabelFileAutoMatch from '@/components/StorageCenter/shipments/whLabelFileAutoMatch.vue';

	export default {

		props: {

			openTime: {

			},

		},
		components: {
			HyElWhUpLoad
			// whLabelFileNameMatch,
			// whLabelFileAutoMatch
		},
		data() {
			return {
				dialogFile: false,

				//附件
				fileWatchKey: '1',
				fileList: [],
				fileId: this.$store.getters.getUserInfo.id,

				listUrl: [],
				// FileLabelUploadKey:'',
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				// this.stepActive = 0;
				//初始化上传控件
				this.listUrl = [];
				// this.tableData = [];

				this.$nextTick(() => {
					try {
						this.$refs.HyElWhUpLoad.initData();
						this.$refs.HyElWhUpLoad.clearFileList();
					} catch (err) {
						console.log(err);
					}
				});
				// if (this.relationId) {
				//   this.getListData();
				// }
			},
			//上传成功回调
			FileUploadSuccessUrl(val) {
				console.log('changeFileUrl', val);
				let listUrl = val.map(item => {
					// "filePath":"","fileName"：“”
					return {
						filePath: item.url,
						fileName: item.name
					};
				});
				console.log('listUrl', listUrl);
				
				this.listUrl = listUrl;
				
				if(this.listUrl && this.listUrl[0] && this.listUrl[0].filePath){
					// this.$alert('上传成功！', this.$t('tips.tipsTitle'), {
					// 	type: 'success'
					// });
					this.$message.success('上传成功')
					this.$emit('success', this.listUrl[0].filePath);
					this.dialogFile = false;
				} else {
					this.$alert('上传失败！', this.$t('tips.tipsTitle'), {
						type: 'warning'
					});
				}
				
				
				
			},
			//校验-
			submitExcelFormAction() {

				// if (this.saveErr) {
				// 	// 校验到后台有误时
				// 	this.submitExcelFormData();
				// } else {
				//   //重新执行-oss 文件上传及回调
				this.$nextTick(() => {
					try {
						this.$refs.HyElWhUpLoad.submitUpload();
					} catch (err) {
						console.log(err);
						//重新提交数据
						// this.submitExcelFormData();
					}
				});
				// }
			},
			//校验-
			// submitExcelFormData() {
			// 	let formData = [];
			// 	this.listUrl.forEach(item => {
			// 		// if (item.fileName && item.filePath) {
			// 			formData.push({
			// 				// id: this.relationId,
			// 				// wh_file_type:this.wh_file_type,
			// 				fileName: item.fileName,
			// 				url: item.filePath
			// 			});
			// 		// }
			// 	});
			// 	// this.submitExcelForm(formData);
			// 	this.$emit('success',formData);
			// },
			// FileLabelUploadSuccess(){
			// 	this.$emit('success')
			// },
			// closeBack(){
			// 	this.dialogFile = false;
			// }

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>